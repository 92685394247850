import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Table,
  toast,
} from "semantic-ui-react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import URL from "../config/config.json";
const baseUrl = URL.baseUrl;

function CertificateVerifyModal({
  isCertificateVerifyModalOpen,
  setIsCertificateVerifyModalOpen,
  testId,
}) {

  const toast = useToast();
  const [certificateId, setCertificateId] = useState("");
  const [certificateData, setCertificateData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hintToastShown, setHintToastShown] = useState(false); // Track if hint toast has been shown
  
  const [companyname,setCompanyName] = useState("");
  const [coursename,setCourseName] = useState("");

  useEffect(() => {
    if (isCertificateVerifyModalOpen) {
      // Reset certificate data and error on modal open
      setCertificateData(null);
      setError(null);
    }
  }, [isCertificateVerifyModalOpen]);

  const handleVerify = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/fetch_certificate_details/${certificateId}/`);
      console.log(response)
      setCertificateData(response.data);
      setError(null);
    } catch (error) {
      setError("Error fetching certificate data");
      setCertificateData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const showHintToast = () => {
    if (!hintToastShown) {
      toast({
        title: "Sample Certificate ID : 1708#####1_3##0",
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      setHintToastShown(true); // Set the state to true to indicate that the hint toast has been shown
    }
  };

  return (
    <Modal
      closeIcon
      open={isCertificateVerifyModalOpen}
      onClose={() => setIsCertificateVerifyModalOpen(false)}
      size="fullscreen"
    >
      <h3 style={{ padding: "10px 30px" }}>Certificate Verification Details</h3>
      <hr />

      <Modal.Content scrolling>
        <Modal.Description>
          <Form>
            <div style={{ display: "flex", marginBottom: "20px", justifyContent: "center" }}>
              <p style={{ margin: "5px", marginRight: "25px" }}><b>Enter Certificate ID</b></p>
              {/* Hint */}
              <p style={{ margin: "5px", marginRight: "25px" }}>
                <span onClick={showHintToast} style={{ cursor: "pointer",color:"blue" }}>? [Hint]</span>
              </p>
            </div>
            <Input
              type="text"
              placeholder="Enter the Certificate ID"
              style={{ width: "60%",marginBottom: "20px",marginLeft:"20%",marginRight:"20%" }}
              value={certificateId}
              onChange={(e) => setCertificateId(e.target.value)}
            />
            <Button
              color="green"
              onClick={handleVerify}
              loading={isLoading}
              disabled={isLoading}
              style={{ display: "block", margin: "0 auto",marginTop:"20px",marginBottom: "20px" }}
            >
              Validate
            </Button>

            {error && (
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            )}

            {certificateData && ( // Conditionally render the table if certificateData exists
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Company</Table.HeaderCell>
                    <Table.HeaderCell>Course</Table.HeaderCell>
                    <Table.HeaderCell>Issuer Designation</Table.HeaderCell>
                    <Table.HeaderCell>Issuer Name</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{certificateData.username}</Table.Cell>
                    <Table.Cell>{certificateData.company_name}</Table.Cell>
                    <Table.Cell>{certificateData.course_name}</Table.Cell>
                    <Table.Cell>{certificateData.designation}</Table.Cell>
                    <Table.Cell>{certificateData.issuer_name}</Table.Cell>
                    <Table.Cell>{certificateData.exam_date}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default CertificateVerifyModal;
